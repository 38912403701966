import posthog, { PostHogConfig } from 'posthog-js';

export const API_KEY = 'phc_xJHLo1oNqnmoRvgKqplInwtsTN5WjGEGeInw4gWzE6I';

export const CONFIGS: Partial<PostHogConfig> = {
  secure_cookie: true,
  // api_host: 'https://app.posthog.com',
  api_host: 'https://sentinela.leadzai.com', // Posthog reverse proxy
  loaded: function (ph) {
    const opts = {
      send_event: true,
    };

    ph.config.debug = ph.isFeatureEnabled('enable-debugging', opts) ?? true;
    ph.config.autocapture = ph.isFeatureEnabled('enable-autocapture', opts) ?? true;
    ph.config.capture_pageview = ph.isFeatureEnabled('enable-capture_pageview', opts) ?? true;
    ph.config.disable_session_recording = !ph.isFeatureEnabled('enable_session_recording', opts) ?? true;
  },
};

export function isLoaded() {
  // unfortunately calls to posthog aren't safeguarded by default with this check
  // eslint-disable-next-line no-underscore-dangle
  return posthog.__loaded;
}
